const cardBagRouter = [
  {
    path: '/cardBag/login',
    name: 'GardBagLogin',
    meta: { title: '登录' },
    component: () => import('@/views/cardBag/login.vue')
  },
  {
    path: '/cardBag/orderList',
    name: 'GardBagOrderList',
    meta: { title: '订单' },
    component: () => import('@/views/cardBag/orderList.vue')
  }
]
export default cardBagRouter
