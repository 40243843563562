import Vue from 'vue'
import VueRouter from 'vue-router'
import shantouRouter from './modules/shantou-project'
import guangfaRouter from './modules/guangfa-project'
import cardBagRouter from  './modules/cardBag'
Vue.use(VueRouter)

const routes = [...shantouRouter, ...guangfaRouter,...cardBagRouter]

const router = new VueRouter({
  routes
})

export default router
